p {
    margin: 15px;
    padding: 15px;
    max-width: 500px;
    max-height: auto;
    text-align: left;
}

#showResult {
    margin: 20px;
    padding: 20px;
    max-width: 500px;
    max-height: auto;
    text-align: center;
}

h3 {
    margin: 5px;
    padding: 5px;
    color: #F67280;
    text-align: left;
    text-shadow: 1px 1px 1px #000000;
}

ul {
    list-style-type: square;
}

hr {
    border-color: #F67280;
}

strong {
    color: #DFE79E;
}