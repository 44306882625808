body {
  max-width: 100%;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #FFFFFF;
  background-image: linear-gradient(#222222, #555555, #222222);
  background-repeat: no-repeat;
  background-position: 100%;
  background-attachment: fixed;
  background-color: #222222;
}

footer {
  font-size: small;
  color: #A4C5C6;
}

a {
  text-decoration: none;
  color: #DFE79E;
}

a:link, a:visited {
  color: #DFE79E;
}

a:hover, a:active {
  color: #A4C5C6;
}