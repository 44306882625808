#main {
  max-width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  box-shadow: 4px 4px 4px #000000;
  border-radius: 10px 50px;
  padding: 15px;
  background-color: #F67280;
  color: #FFFFFF;
  font-size: large;
  text-align: justify;
}

#logo {
  max-width: 250px;
  max-height: 250px;
  animation: rotate 20s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

h1 {
  color: #DFE79E;
  text-shadow: 0px 0px 15px #000000;
}

h2 {
  font-size: large;
  margin: 0;
  padding: 0;
}

#presentation {
  margin: 0px;
  padding: 10px;
  text-align: justify;
  font-size: medium;
}

select {
  max-width: 500px;
  height: auto;
  border-radius: 20px;
  margin: 20px;
  padding: 15px;
  background-color: #A7E9AF;
  font-size: medium;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 0px 20px #000000;
  color: #333333;
}

select:focus {
  border: none;
  outline: 0;
}